@tailwind base;

@tailwind components;

@tailwind utilities;

:root{
    --blue-theme: #0284C7;
    --whizzdom-purple: #7E52A0;
}

    
/* absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-space-between p-4 */
/* .navbar{
    background: transparent;
} */


/* w-full mx-autp flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4 */

.navbar-buttons{
    /* background-color: white; */
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* background-color: white; */
    /* padding: 10px; */

}

.navbar{
    background-color: var(--whizzdom-purple);
    padding-bottom: 1.5rem;
    padding-top: 3rem;
}

.logout{
    box-shadow: 'none';
    text-transform: 'none';
    font-size: 16;
    padding: 10%;
    border: '1px solid';
    line-height: 1.5;
    background-color: '#0063cc';
    color: white;
    border-color: '#0063cc';
    font-family: 
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"';
    /* padding: 5%; */
    /* position: absolute; */
    /* padding-right: "100px"; */
    /* text-align: left; */
    /* width: 100px; */

}

.logout:hover{
    background-color: '#0069d9';
    border-color: '#0063cc';
    box-shadow: 'none';
}

.logout:active{
    box-shadow: 'none';
    background-color: '#0062cc';
    border-color: '#005cbf';
}

.logout:focus{
    box-sizing: '0 0 0 0.2rem rgba(0,123,255,.5)',
}


.register-for-mock-exam-button{
    color: white;
    background-color: #D2042D;
    text-transform: 'none';
    font-size: 16;
    padding: 10%;
    border: '1px solid';
    font-style: bold;
    font-weight:800;
    font-family:
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
    border-radius: 10px;
    white-space: nowrap;
    text-align: center;

    /* hover: background-color: */


    /* position:absolute; */
    /* text-align: right; */
    /* width: 200px */
}

.register-for-mock-exam-button:hover{
    background-color: white;
    color: #D2042D;
}

.worksheet-review-navbar{
    color: rgba(2, 132, 199);
    background-color: white;
    /* margin: 4px; */
    /* margin-inline: 30px; */
    /* margin-right: 30px; */
    justify-content: 'center';
    text-align: center;
    border-radius: 10px;
    margin-left: 25%;
    margin-right: 25%;
    border-color: rgba(2, 132, 199);
    border-width: 1px;

}

.worksheet-review-navbar-selected{
    color: white;
    background-color: rgba(2, 132, 199);
    /* margin: 4px; */
    /* margin-inline: 30px; */
    /* margin-right: 30px; */
    justify-content: 'center';
    text-align: center;
    border-radius: 10px;
    margin-left: 25%;
    margin-right: 25%;
    border-width: 1px;
    border-color: rgba(2, 132, 199);
}


.square{
    background-color: white;
}

.toTopicsList{
    background-color:rgba(2, 132, 199);
    color: white;
    margin-top: 3%;
    margin-left: -1%;
    border-radius: 5px;
    padding: 1%;
}

.fromWorksheetReview{
    background-color:white;
    color: rgba(2, 132, 199);
    border-radius: 5px;
    padding: 2%;
    border: '10pt solid';
    border-color: rgba(2, 132, 199);
    outline: #0fa5e9;
    border-width: 1px;
 
}

.fromWorksheetReview:hover{
    background-color:rgba(2, 132, 199);
    color: white;
    border-radius: 5px;
    padding: 2%;
}


.num-selection-buttons{
    display: flex;
    background-color:rgba(2, 132, 199);
    color: white;
    margin-right: 30%;
    margin-top: 3%;
    margin-left: -4%;
    border-radius: 5px;
    padding: 1%;
}

.pricing-container{
    align-items: center;
    justify-content: center;
    text-align:center;
    background: #7e52a0;
    padding-top: 30px;
    padding-bottom: 48px;
}

.pricing-section-header {
    color: white;
}

.pricing-cards {
    display: inline-flex;
    flex-direction: row;
    padding: 3%;
    /* margin: 5%; */
}

.pricing-card{
    margin-right: 30%;
    padding-left: 10%;
}

.pricing-details-block{
    padding: 5%;
    text-align: left;
}

.pricing-details{
    padding-inline: 20px;
    display: flex;
    flex-direction: row;
}

.fa-check{
    padding-right: 4px;
    padding-top: 4px;
    color: #34d399;
}

.pricing{
    padding: 5%;
    font-weight: bold;
    font-size: 20px;
}

.pricing-button-container {
    padding: 3%;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;

}

.pricing-button-pro {
    font-size: 20px;
    padding: 2%;
    border-radius: 10px;
    background-color: #D2042D;
    font-weight: bold;
    color: white;
    border: 2px solid #D2042D;
}

.pricing-button-pro:hover{
    background-color: white;
    color: #D2042D;
    font-weight: bold;
    border: 2px solid #D2042D;
}

.pricing-button-standard {
    font-size: 20px;
    padding: 3%;
    border-radius: 10px;
    background-color: #38BCFC;
    font-weight: bold;
    color: white;
    border: 2px solid #38BCFC;
}

.pricing-button-standard:hover {
    background-color: white;
    color: #38BCFC;
    font-weight: bold;
    border: 2px solid #38BCFC;
}

.premium-pricing-padding{
    padding-bottom: 38px;
}

.sign-up-buttons-group {
    display: flex;
    justify-content: space-between;
}

.sign-up-buttons{
    flex-direction: "row";
    color: black;
    /* background-color: blue-gray; */
    text-align: center;
    border: '1px solid';
}

.sign-up-buttons:hover{
    font-weight: bold;
}

.footer{
    position: fixed;
    /* left: 0; */
    bottom: 0;
    /* right: 0; */
    /* background-color: red; */
    color: gray;
    padding-bottom: 1.5rem;
    font-size: small;
    font-weight: bold;
}

.english-question-answer-option{	
    padding: 2px	
}
	
.english-question-answer-option:active {	
    border-style: outset;	
    outline: 0;	
 }	
 
.english-question-answer-option:focus {	
    border-style: outset;	
    outline: 0;	
 }

 .table-container {
    width: 95%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin: 30px 0px;
  }

  .mock-exam-registration-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin: 10px 10px;
  }
  
  .table-header {
    background-color: #880484;
    color: #fff;
    font-weight: bold;
  }
  
  .table-row {
    background-color: #fff;
    border-bottom: 1px solid #880484;
    color: #333;
  }
  
  /* .table-row:nth-of-type(odd) {
    background-color: #eee;
  } */
  
  .table-row:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  
  .table td,
  .table th {
    padding: 10px;
  }

  .mock-exam-registration-table td,
  .mock-exam-registration-table th {
    padding: 10px;
  }
  
  @media only screen and (max-width: 400px) {
    .mock-exam-registration-table td:first-child,
    .mock-exam-registration-table th:first-child {
      display: none;
    }
  }

  @media only screen and (max-width: 800px) {
    .table td:last-child,
    .table th:last-child {
      display: none;
    }
  }

  .mockExamInstructionsList {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding-top: 20px;
    padding-left: 25px;
  }

  .mockExamInstruction {
    padding: 10px
  }

  .upgrade-to-standard-user-button{
    color: white;
    background-color: #9932CC;
    text-transform: 'none';
    font-size: 25px;
    font-weight: bold;
    padding-block: 10px;
    padding-inline: 10px;
    margin: 3%;
    border-color: #f6ae2d;
    font-style: bold;
    font-family:
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
    border-radius: 15px;
    white-space: nowrap;
    text-align: center;
    border-width: 5px;

}

.upgrade-to-standard-user-button:hover{
    background-color: white;
    color: #9932CC;
    border: '10px solid';
    border-radius: 15px;
    border-width: 5px;
    border-color: #9932CC; 
}