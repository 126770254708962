:root{
    --mock-exam-nav-bar: #7E52A0;
}

/* 
.mock-exam-navbar{
    background-color: var(--mock-exam-nav-bar);
    padding-bottom: 1.5rem;
    padding-top: 3rem;
    padding-inline: 4px;
    width: full;
    margin-inline: auto;
} */


.start-mock-exam-button{
    color: white;
    background-color: #9932CC;
    text-transform: 'none';
    font-size: 17px;
    font-weight: bold;
    padding-block: 10px;
    padding-inline: 10px;
    margin: 2%;
    border: '5px solid';
    font-style: bold;
    font-family:
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
    border-radius: 100px;
    white-space: nowrap;
    text-align: center;
    border-width: 3px;

}

.start-mock-exam-button:hover{
    background-color: white;
    color: #9932CC;
    border: '10px solid';
    border-radius: 50px;
    border-width: 3px;
    border-color: #9932CC; 
}

.start-mock-exam-button-disabled {
    font-size: 17px;
    font-family:
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
    opacity: 1;
    padding-block: 10px;
    padding-inline: 10px;
    background-color: #eee;
    color: #aaa;
    border: 0px solid #ddd;
    border-radius: 50px;
  }

  .start-mock-exam-button-disabled:hover::before {
    content: "This button is currently disabled";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
  }

  .closed-column-sidebar-numbers-nav {
    text-align: center;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10%;
    border-radius: 100%;
    border: 2px solid purple;
    color: purple;
  }

  .closed-column-sidebar-numbers-nav-selected {
    text-align: center;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 10%;
    border-radius: 100%;
    border: 2px solid white;
    color: white;
    background-color: purple;
  }

  /* .submit-question-button-disabled {
    border-width: 1px;
    border-radius: 50px;
    border-color: #7c7580; 
    background-color: #ada9af;
    padding: 10px;
    color: white;
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  } */
  
  .submit-question-button-disabled {
    border-width: 1px;
    border-radius: 50px;
    border-color: #7c7580; 
    background-color: white;
    color: #7c7580;
    cursor: not-allowed;
    opacity: 0.5;
    padding: 10px;
    pointer-events: none;
  }
  
  .submit-question-button {
    border-width: 1px;
    border-radius: 50px;
    border-color: #9932CC; 
    padding: 10px;
    color: #9932CC;
  }

  .submit-question-button:hover {
    border-width: 1px;
    border-radius: 50px;
    border-color: #9932CC; 
    padding: 10px;
    background-color: #9932CC;
    color: white;
  }  
  
  .accordion-item:nth-child(even) {
    background-color: #f9f9f9; /* light cream color */
  }
  
  .accordion-item:nth-child(odd) {
    background-color: #fff; /* white color */
  }


  .mock-exam-review-navbar{
    color: rgba(2, 132, 199);
    background-color: white;
    /* margin: 4px; */
    /* margin-inline: 30px; */
    /* margin-right: 30px; */
    justify-content: 'center';
    text-align: center;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border-color: #9932CC;
    color: #9932CC;
    border-width: 1px;
}


.mock-exam-review-navbar-selected{
    color: white;
    background-color:#9932CC;
    /* margin: 4px; */
    /* margin-inline: 30px; */
    /* margin-right: 30px; */
    justify-content: 'center';
    text-align: center;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    border-width: 1px;
    border-color: #9932CC;

}
