.landing-page-nav-bar-white-logo {
    max-width: 40%;
    max-height: 100%;
    flex-grow: 1;
    margin-left: 0%;
    /* display: block; */
    /* width: 200px; */

}

@media screen and (xs) {
    .landing-page-nav-bar-white-logo {
        display: none;
    }
}
/* 
@media screen and (sm) {
    .landing-page-nav-bar-white-logo {
        display: block;
    }
} */

.sign-up, .log-in{
    padding-inline: 10px;
    padding-block: 10px;
    background-color: white;
    color: darkblue;
    font-size: small;
    font-weight: bold;
    border-radius: 5%;
    display: inline-block;
    /* width: 120px; */
    /* height: 30px;
    width: 80px; */
    text-align: center;
}

.banner-sign-up-button{
    margin: 5%;
    padding-inline: 7%;
    display: inline;
    font-size: x-large;
    color: #D2042D;
    font-weight: bolder;
    background-color: white;
    border: 2px solid #D2042D;
    border-radius: 30px;
    padding-block: 4%;
}

.banner-sign-up-button:hover {
    background-color: #D2042D;
    color: white;
    font-weight: bold;
    border: 2px solid white;
}

.eleven-plus-info-blog-title-and-image {
    margin-top: -50px;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
}

.eleven-plus-info-blog-image {
    margin-top: 5px;
    padding: 25px;
    width: 60%;
}

@media only screen and (max-width: 768px) {
    .eleven-plus-info-blog-image {
        width: 85%;
    }
}

.eleven-plus-preparation-blog-content {
    /* padding-left: 400px;
    padding-right: 400px; */
    /* width: 60%; */
    padding-top: 30px;
    padding-bottom: 30px;
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* text-align: -moz-center;
    text-align: -webkit-center; */
}

.eleven-plus-preparation-blog-subheading {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: -moz-center;
    text-align: -webkit-center;
}

.eleven-plus-preparation-blog-bullet-point {
    /* flex: 1; */
    padding: 10px;
}

.eleven-plus-preparation-blog {
    padding-left: 50px;
    padding-right: 50px;
}


.eleven-plus-blog-image {
    display:block;
    /* other properties */
  }
  
  @media only screen and (max-width: 768px) {
      .eleven-plus-blog-image {
        display:none;
      }
  }

  .mock-exam-landing-background{
    background-color: rgba(31, 7, 39, 0.5); /* Tint color */
    background-blend-mode: multiply;

    height: 100%;
    /* min-width: 1024px; */

    width: 100%;
    /* height: auto; */
      
    /* Set up positioning */
    position: fixed;
    top: 50;
    left: 0;

    margin: 0;
    padding: 0;
    border: none;

    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    /* height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
  }

  /* .mock-exam-landing-background:after {
    position: absolute;
  	height: "20px";
  	width: 100%;
  	background-color: rgba(0,0,0,.5);
  	top: 0;
  	left: 0;
  	display: block;
  	content: "";
  } */

.eleven-plus-mock-exam-selling-points{
    margin-bottom: -15px;
    /* color: aqua; */
  }

.eleven-plus-book-now-button{
    color: white;
    background-color: #9932CC;
    text-transform: 'none';
    font-size: 20px;
    font-weight: bold;
    padding-block: 20px;
    padding-inline: 50px;
    margin: 2%;
    border: '10px solid';
    font-style: bold;
    font-family:
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
    border-radius: 100px;
    white-space: nowrap;
    text-align: center;
    border-width: 1px;

}

.eleven-plus-book-now-button:hover{
    background-color: white;
    color: #9932CC;
    border: '10px solid';
    border-radius: 100px;
    border-width: 3px;
    border-color: #9932CC; 
}

.upcoming-exams-table-register{
    margin: 5%;
    padding-inline: 7%;
    display: inline;
    color: #D2042D;
    font-weight: bolder;
    background-color: white;
    border: 2px solid #D2042D;
    border-radius: 30px;
    padding-block: 4%;
}